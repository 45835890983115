const interactive =  {
  variant: 'cards.primary',
  p: 4,
  '@media (hover: hover) and (pointer: fine)': {
    ':hover': {
      transform: `translateY(-0.25rem)`,
      boxShadow: `0 0 25px rgba(140,152,164,.3)`
    }
  }
};

export default interactive;
