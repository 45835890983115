// Importing default theme color object to mutate it
import defaultFormTheme from '@elegantstack/solid-ui-theme/src/forms'

const formTheme =  {
  ...defaultFormTheme,
  field: {
    ...defaultFormTheme.field,
    bg: 'betaLighter'
  },
  input: {
    ...defaultFormTheme.input,
    bg: 'betaLighter'
  },
}

export default formTheme;