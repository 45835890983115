// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from './color-preset'

const colorTheme =  {
  ...defaultColors,
   // Alpha (primary)
   alphaLighter: colors.teal[100],
   alphaLight: colors.teal[200],
   alpha: colors.teal[300],
   alphaDark: colors.teal[500],
   alphaDarker: colors.teal[700],
   // beta (secondary)
   betaLighter: colors.coolGray[100],
   betaLight: colors.coolGray[300],
   beta: colors.coolGray[500],
   betaDark: colors.coolGray[700],
   betaDarker: colors.coolGray[800]
}

export default colorTheme;